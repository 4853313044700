.about {
  color: #282c34;
  margin-left: auto;
  margin-right: auto;
  margin-top: 14vh;
  padding: 30px;
  text-align: left;
  max-width: 600px;
}

.about-header {
  font-size: 2em;
  text-align: left;
}

.about-svg {
  fill: none;
  stroke: rgb(158, 198, 184) !important;
  stroke-width: 1px;
  margin: 0;
  margin-top: -100px;
  left: auto;
  top: auto;
  z-index: -1;
}
