body {
  background-color: #FAFAFA;
  /* background-color: #282c34; */
  color: #fafafa;
  margin: 0;
  padding: 0; 
  font-family: 'PT Sans Caption', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* PT Sans Caption */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.app {
  height: 100vh;
  text-align: center;
}

.red-indicator {
  background: #e00;
  color: white;
  font-size: 12px;
  line-height: 1em;
  padding: 2px 5px;
  border-radius: 100px;
  position: absolute;
  top: -4px;
  right: -4px;
}


/* POSITIONING */

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}
