.shirt-config {
  color: #333;
  padding: 24px;
  margin: 0;
  text-align: left;
}

.sc-title {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.sc-cart-button {
  border-radius: 2px;
  background-color: #282c34;
  padding: 10px;
  padding-top: 8px;
  padding-bottom: 6px;
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 2;
}

.sc-cart-icon {
  fill: white;
}

.sc-option-title {
  font-size: 16px;
  margin-top: 6px;
}

.sc-color-option {
  border-radius: 50%;
  border: 1px solid #777;
  display: inline-block;
  height: 32px;
  margin: 10px;
  margin-top: 12px;
  width: 32px;
}

.sc-color-option:hover {
  cursor: pointer;
}

.sc-color-selected {
  /* border-color: #5C196B; */
  border: 4px solid #5C196B;
}

.sc-placement-option {
  border: 2px solid white;
  border-radius: 0px;
  margin: 10px;
  margin-top: 12px;
  width: 64px;
  height: 64px;
}

.sc-placement-option:hover {
  cursor: pointer;
}

.sc-placement-selected {
  border-color: #5C196B;
}

.sc-action-button {
  margin: 10px;
}

.sc-size-selector {
  width: 60%;
  max-width: 200px;
  margin: 10px;
}
