.header {
  background-color: #282c34;
  margin: 0;
  text-align: left;
}

.header-title {
  color: #2B90D9;
  font-family: 'PT Sans Caption'; 
  font-size: 60px;
  position: absolute;
  top: 18px;
  left: 48px;
  font-weight: normal;
  letter-spacing: 3px;
  margin: 0;
}

.header-title-back {
  color: #FFDA8E;
  position: absolute;
  top: 20px;
  left: 46px;
  font-family: 'PT Sans Caption'; 
  font-size: 60px;
  font-weight: normal;
  letter-spacing: 3px;
  margin: 0;
}
