.shipping-title {
  font-size: 16px;
  font-weight: bold;
  margin: 6px;
  margin-bottom: 14px;
  text-align: left;
}

.shipping-option-input {
  margin-top: 4px;
  vertical-align: top;
}

/* General declarations to make custom radios feel nice to click on. */
.shipping-option-container input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  
  /* Input's box-sizing is set to border-box by default. */
  box-sizing: content-box;
  cursor: pointer;
  outline: none;
  position: relative;
}

.shipping-option-container input[type="radio"] {
  border-radius: 100%;
  border: 1px solid #5C196B;
  box-shadow: inset 0 0 0 2px #fff;
  width: 14px;
  height: 14px;
  transition: all .1s;
}
.shipping-option-container input[type="radio"]:checked {
  background: #5C196B;
}

.shipping-option-container {
  margin: 10px;
}

.shipping-option-label {
  display: inline-block;
  max-width: 90%;
  padding-left: 6px;
}
.shipping-option-label:hover {
  cursor: pointer;
}

.shipping-checkout-button-container {
  margin-top: 20px;
  margin-left: 20px;
}

.shipping-options-loading-message {
  margin-left: auto;
  margin-right: auto;
  margin: 8px;
}


.shipping-shipping-information-link {
  color: #5C196B;
  display: inline-block;
  text-decoration: underline;
}

.shipping-shipping-information-link:hover {
  cursor: pointer;
}