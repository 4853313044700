.faq-container {
  color: #282c34;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  padding: 32px;
  right: 0;
  text-align: left;
  margin-top: 10vh;
}

.faq-qa-container {
  margin-top: 30px;
  padding-left: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-left: 1px solid hsl(0,0%,80%);
}

.faq-question {
  font-weight: bold;
}

.faq-answer {
  margin-top: 8px;
}

.faq-contact-link {
  color: #5C196B;
  text-decoration: underline;
}
.faq-contact-link:hover {
  cursor: pointer;
}
