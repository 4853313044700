.home-links {
  text-align: left;
  margin-left: 20%;
}

.home-link {
  margin-left: 10px;
  margin-top: 10px;
}

.home-shirt-config-container {
  background-color: rgba(255, 218, 142, 0.3);
}

.home-content-window {
  min-height: 100vh;
}

.home-shirt-window {
  margin-top: 10vh;
  padding: 10px;
}

.home-regenerate-button-container {
  margin-bottom: 2vh;
  margin-left: 10%;
  margin-top: 3vh;
  text-align: left;
}
