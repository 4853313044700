
.checkout-section-title {
  font-weight: bold;
  margin-bottom: 4px;
  margin-top: 16px;
}

.checkout-info-item {
  padding-left: 6px;
  padding-top: 2px;
}

.checkout-section-order-info {
  border: 1px solid #5C196B;
  border-radius: 3px;
  padding: 14px;
  margin-bottom: 20px;
}

.checkout-price-item {
  padding: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.checkout-button-container {
  margin-top: 10px;
  text-align: center;
  width: 100%
}

.checkout-prices {
  border-radius: 3px;
  border: 1px solid #FFDA8E;
  padding: 10px;
  margin: 6px;
}
