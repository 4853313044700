

.share {
  background-color: #F5F5F5;
  border-radius: 2px;
  box-shadow: 0 0 2px 1px rgba(0,0,0,.15);
  color: #333;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  padding: 20px;
  position: absolute;
  right: 0;
  text-align: left;
  top: 10vh;
}

@media only screen and (max-width: 768px) {
  .share {
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    padding: 10px;
    padding-top: 30px;
  }
}

.share-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.share-background {
  background-color: rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.share-url {
  border: 1px solid #FFDA8E;
  display: inline-block;
  padding: 12px 20px;
  overflow-x: auto;
  text-decoration: none;
}

.share-url-container {
  width: 100%;
}

.share-button {
  vertical-align: top;
}