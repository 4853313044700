.purchase-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.purchase-info-title {
  font-size: 16px;
  font-weight: bold;
  text-align: right;
}

.purchase-shirt-item {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 30px;
}

.purchase-column {
  display: flex;
  width: 0;
  flex-grow: 1;
  flex-direction: column;
}

.purchase-shirt-preview {
  border: 1px solid hsl(0,0%,80%);
  border-radius: 2px;
  background: none;
  /* background-color: hsl(0,0%,80%); */

  padding: 14px;
  margin: 4px;
  /* border: none; */
  transition: 100ms all;
  outline: none;
}

.purchase-shirt-preview:hover {
  border-color: hsl(0,0%,60%);
  cursor: pointer;
}

.purchase-info-container:not(:last-of-type) {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid hsl(0,0%,80%);
}

.purchase-input-item {
  padding: 4px;
  text-align: left;
}

.purchase-input-item-topmargin {
  margin-top: 10px;
}

.purchase-empty { 
  border: 1px solid #5C196B;
  border-radius: 4px;
  margin: 20px;
  padding: 14px;
}

.purchase-space-between {
  justify-content: space-between;
  align-items: center;
}

.purchase-button {
  background: none;
  border: 1px solid hsl(0,0%,80%);
  border-radius: 3px;
  font: inherit;
  font-size: 14px;
  margin: 2px;
  margin-top: 10px;
  height: 38px;
  margin-right: auto;
  outline: none;
  padding: 2px 14px;
}

.purchase-button:hover {
  border: 1px solid hsl(0,0%,60%);
  cursor: pointer;
}

.purchase-button-red {
  color: #e00;
}

.purchase-small-select {
  max-width: 72px;
  margin-left: auto;
}

.purchase-info-item {
  margin-top: 6px;
  padding: 0px 10px;
  text-align: left;
}

.purchase-money-text {
  display: inline-block;
  margin-left: 5px;
}

.purchase-label {
  font-weight: 700;
  color: #5C196B;
}

.purchase-info-amount {
  padding-top: 6px;
}

.purchase-add-another {
  color: #5C196B;
  font-weight: bold;
  margin: 10px;
  margin-left: 20px;
}
.purchase-add-another:hover {
  color: #4C095B;
  cursor: pointer;
}

.purchase-subtotal {
  text-align: center;
}

.purchase-remove-item {
  font-weight: bold;
  text-align: center;
}

.purchase-remove-item:hover {
  cursor: pointer;
}

.purchase-subtotal-container {
  border: 1px solid black;
  border-radius: 2px;
  padding: 10px;
}

.purchase-subtotal-title {
  text-align: center;
}

.purchase-continue-button-container {
  margin-right: 8.3333%;
  margin-top: 16px;
  text-align: right;
}

.purchase-controls-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
