.contact-container {
  background-color: #F5F5F5;
  border-radius: 2px;
  box-shadow: 0 0 2px 1px rgba(0,0,0,.15);
  color: #333;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  padding: 20px;
  position: absolute;
  right: 0;
  text-align: left;
  top: 10vh;
}

.contact-background {
  background-color: rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.contact-title {
  font-size: 1.1em;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.contact-description {
  font-size: 12px;
  color: gray;
  margin-top: 6px;
  margin-bottom: 10px;
  margin-left: 6px;
}

.contact-body-title {
  font-size: 12px;
  margin-top: 10px;
  margin-left: 4px;
  margin-bottom: 2px;
}

.contact-body {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid hsl(0,0%,80%);
  border-radius: 2px;
  box-shadow: none;
  font-size: 14px;
  outline: 0;
  padding: 16px;
  min-height: 120px;
  /* transition: all 0.1s ease-in-out; */
  width: 100%;
}
.contact-body:focus {
	outline: 0;
	border: 1px solid #5C196B;
}

.contact-error-message {
  border: 2px solid #fa755a;
  border-radius: 4px;
  margin: 20px;
  margin-bottom: 10px;
  padding: 10px;
}

.contact-success-message {
  border: 2px solid #5afa97;
  border-radius: 4px;
  margin: 20px;
  margin-bottom: 10px;
  padding: 10px;
}

.contact-send-button-container {
  margin: 8px;
  margin-bottom: 0;
  text-align: right;
}

.contact-sending-message {
  text-align: center;
  margin: 14px;
}
