.confirmation-confirmed-message {
  margin: 10px;
}

.confirmation-message-inner {
  margin-top: 10px;
}

.confirmation-make-another {
  color: #5C196B;
  margin-top: 14px;
  text-decoration: underline;
  text-decoration-color: #5C196B;
  text-align: center;
}

.confirmation-make-another:hover {
  cursor: pointer;
}

.confirmation-loading {
  margin: 40px;
  margin-bottom: 90px;
  text-align: center;
}

.confirmation-error-message {
  padding: 10px;
  border-radius: 2px;
  background-color: #D32;
}

.confirmation-track-url {
  color: #5C196B;
  display: inline;
  margin-left: 2px;
  overflow-x: auto;
  text-decoration: none;
}
