
.sofloo-loader-container {
  animation: soflooLoaderContainerAnim 2s alternate infinite ease-in-out;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
}
.sofloo-loader {
  animation: soflooLoaderInnerBoxAnim 2s alternate infinite ease-in-out;
  display: inline-block;
  height: 32px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  width: 32px;
}
/* outline: 1px solid transparent; */

.sofloo-loader-outer-box {
  background-color: #5C196B;
  border-radius: 50%;
  height: 32px;
  position: absolute;
  width: 32px;
  animation: soflooLoaderOuterBoxAnim 0.5s;
}
.sofloo-loader-inner-box {
  background-color: #FFDA8E;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  position: absolute;
  z-index: 1;
  animation: soflooLoaderInnerBoxAnim 1.25s alternate infinite ease-in-out;
}
@keyframes soflooLoaderOuterBoxAnim {
  0% {transform: scale(0);}
  100% {transform: scale(1);}
}

@keyframes soflooLoaderInnerBoxAnim {
  0% {transform: scale(0);}
  100% {transform: scale(0.9);}
}

@keyframes soflooLoaderContainerAnim {
  /* 0% {transform: rotate(45deg);}
  100% {transform: rotate(135deg);} */
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@keyframes soflooLoaderAnim {
  0% {transform: scale(0);}
  100% {transform: scale(1);}
}
