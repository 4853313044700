.pnf-container {
  color: #333;
  margin: 40px;
  margin-top: 20vh;
  text-align: center;
}

.pnf-title {
  margin: 20px;
}

.pnf-home-link {
  color: #5C196B;
  font-size: 16px;
  text-decoration: underline;
}
.nf-home-link:hover {
  cursor: pointer;
}
