/* .pay {
  background-color: #F5F5F5;
  border-radius: 2px;
  box-shadow: 0 0 2px 1px rgba(0,0,0,.15);
  color: #333;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  padding: 20px;
  position: absolute;
  right: 0;
  text-align: left;
  top: 10vh;
} */

.pay-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.pay {
  color: #333;
  padding: 20px;
  max-width: 640px;
  margin: 0 auto;
  text-align: left;
}

/* @media only screen and (max-width: 768px) {
  .pay {
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    padding: 10px;
    padding-top: 30px;
  }
} */

.pay-back-button {
  color: #5C196B;
  font-size: 14px;
  margin: 0px;
}

.pay-back-button:hover {
  cursor: pointer;
}

.pay-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 14px;
  margin-top: 14px;
  text-align: center;
}

.pay-error-message {
  border: 1px solid #e00;
  border-radius: 4px;
  margin: 20px;
  margin-bottom: 10px;
  padding: 14px;
}
