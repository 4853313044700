
.ci-section-title {
  font-size: 18px;
  margin-bottom: 4px;
  text-align: left;
}

.ci-input-item {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.ci-item-label {
  font-size: 14px;
  margin-bottom: 6px;
  text-align: left;
}

.ci-region-container {
  margin-top: 8px;
}

.ci-section {
  margin-top: 10px;
}

.ci-back-button-icon {
  font-size: 42px;
  vertical-align: sub;
}

.ci-info-label {
  text-align: left;
  margin-top: 0 !important;
}

.ci-info-item {
  text-align: right;
  margin-top: 0 !important;
}

.ci-total {
  font-weight: bold;
  text-align: left;
}

.ci-total-item {
  font-weight: bold;
  text-align: right;
}

.ci-button-container {
  text-align: right;
  position: relative;
  margin: 20px;
}

.ci-zip {
  padding-left: 4px;
}

.ci-input-item-checkbox {
  display: flex;
  align-items: center;
}

.ci-input-item-checkbox input {
  flex-grow: 0;
  margin-right: 10px;
}

.ci-input-item-checkbox span {
  flex: 1;
}