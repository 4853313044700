.shirt {
  position: relative;
  width: 100%;
}

.shirt-preview-shirt-container {
  padding: 4px;
  position: relative;
}

.shirt-preview-shirt {
  margin-left: auto;
  margin-right: auto;
  max-height: 70vh;
  max-width: 100%;
  position: relative;
}

.shirt-sofloo-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.shirt-sofloo-art-image {
  margin-left: auto;
  margin-right: auto;
}

.shirt-sofloo-art-loading {
  color: #282c34;
  margin-left: auto;
  margin-right: auto;
  margin-top: 26vh;
}

.shirt-preview-container {
  text-align: center;
}

.shirt-generating-mockups {
  color: #5C196B;
  padding-top: 14vh;
  margin-left: auto;
  margin-right: auto;
}

.shirt-generating-art {
  color: #5C196B;
  padding-top: 14vh;
  margin-left: auto;
  margin-right: auto;
}

.shirt-generating-mockups-failed {
  color: #5C196B;
  padding-top: 14vh;
  margin-left: auto;
  margin-right: auto;
}

.shirt-generate-mockups-again {
  color: #5C196B;
  margin-top: 14px;
  text-decoration: underline;
  text-decoration-color: #5C196B;
  text-align: center;
}

.shirt-generate-mockups-again:hover {
  cursor: pointer;
}
