.generative-generate-button {
  margin: 16px;
}

.generative-generate-button::after {
  /* box-shadow: 0 5px 15px rgba(0,0,0,0.3); */
  /* box-shadow: 0 5px 15px #FFDA8E; */
  box-shadow: 0 5px 15px red;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.generative-next-button:hover {
  cursor: pointer;
}

/* Decent button dropdown shadow. */
/* box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.5); */

.sofloo-button-inverse {
  background: none;
  border-radius: 0px;
  border: 1px solid #5C196B;
  color: #5C196B;
  display: inline-block;
  padding: 10px 20px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease 0s;
}

.sofloo-button-inverse:hover {
  cursor: pointer;
}

.sofloo-button-inverse::before {
  background: #FFDA8E;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 4px;
  left: -4px;
  transition: all 0.3s ease 0s;
  z-index: -1;
}

.sofloo-button-inverse:hover::before {
  box-shadow: 0 3px 1px -1px rgba(0, 0, 0, 0.5);
  top: 0px;
  left: 0px;
}

.sofloo-button-small {
  background-color: #5C196B;
  border-radius: 0px;
  box-shadow: -4px 4px 0px 0px #FFDA8E;
  color: white;
  display: inline-block;
  padding: 8px 22px;
  margin-top: 10px;
  margin-left: 6px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease 0s;
}

.sofloo-button-small:hover {
  box-shadow: 0px 0px 0px 0px #FFDA8E;
  cursor: pointer;
}
.sofloo-button-small::before {
  content: "";
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  transition: all 0.3s ease 0s;
  z-index: -1;
}
.sofloo-button-small:hover::before {
  box-shadow: 0 3px 1px -1px rgba(0, 0, 0, 0.5);
}

.sofloo-button {
  background-color: #5C196B;
  border-radius: 0px;
  box-shadow: -4px 4px 0px 0px #FFDA8E;
  color: white;
  display: inline-block;
  padding: 12px 22px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease 0s;
}

.sofloo-button:hover {
  box-shadow: 0px 0px 0px 0px #FFDA8E;
  cursor: pointer;
}

.sofloo-button::before {
  content: "";
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  transition: all 0.3s ease 0s;
  z-index: -1;
}

.sofloo-button:hover::before {
  box-shadow: 0 3px 1px -1px rgba(0, 0, 0, 0.5);
}

.generative-build-tee-button {
  margin: 16px;
}

.generative-mobile-container {
  min-height: 420px;
  margin: auto;
  position: relative;
}

.generative-loading-container {
  height: 500px;
  margin: auto;
  position: relative;
}

.generative-loading-text {
  color: #5C196B;
  font-size: 16px;
  padding-top: 200px;
}

.generative-loading {
  margin-top: 24px;
  width: 50px;
}

.generative-try-again {
  color: #5C196B;
  margin-top: 14px;
  text-decoration: underline;
  text-decoration-color: #5C196B;
  text-align: center;
}

.generative-try-again:hover {
  cursor: pointer;
}

.generative {
  min-height: 100vh;
  position: relative;
  text-align: center;
}

.generative-about-info {
  color: #282c34;
  margin-bottom: 14px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 14px;
  max-width: 600px;
  padding: 8px;
}

.generative-buttons {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
}

.generative-faq {
  color: #5C196B;
  font-size: 13px;
  position: absolute;
  bottom: 4px;
  right: 64px;
  text-decoration: underline;
}

.generative-contact-us {
  color: #5C196B;
  font-size: 13px;
  position: absolute;
  bottom: 4px;
  right: 8px;
  text-decoration: underline;
}
.generative-contact-us:hover {
  cursor: pointer;
}

.generative-view-algorithm {
  color: #5C196B;
  font-size: 13px;
  position: absolute;
  bottom: 4px;
  left: 8px;
  text-decoration: underline;
}
.generative-view-algorithm:hover {
  cursor: pointer;
}

.generative-sofloo-image {
  margin-top: 14vh;
  margin-bottom: -10vh;
  max-height: 60vh;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.generative-sofloo-image-not-loaded {
  opacity: 0.1;
}

.generative-sofloo-image-loader-container {
  position: absolute;
  top: 20vh;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
