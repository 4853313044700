
.coupon-toggle:hover {
  cursor: pointer;
}

.coupon-toggle svg {
  /* display: inline-block; */
  vertical-align: bottom;
}

.coupon-title {
  display: inline-block;
  font-size: 1.5em;
}

.coupon-interior {
  padding: 4px 16px;
  margin-bottom: 32px;
}
