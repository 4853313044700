.close-button {
  font-family: sans-serif;
  font-size: 20px;
  font-weight: lighter;
  position: absolute;
  top: 8px;
  right: 14px;
}

.close-button:hover {
  cursor: pointer;
}

.close-button-disabled {
  display: none;
}